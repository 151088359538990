import { Article } from 'schema-dts'

import { EntityElastic } from '../../types/entity'
import { CompleteStructuredDataParserProps, ProcessedArticleStructuredData } from '../../types/seo'
import stripHtml from '../stripHtmlTags'
import truncate from '../truncateString'
import { applyOverrideableSeoProperties } from './applyOverrideValue'
import { completeAuthorStructuredData } from './author'
import parseBasicSchemaProperties, { ParsedBasicProperties } from './basicSchemaProperties'
import { parseReferencableThing } from './referenceThing'

export const completeArticleStructuredData: (props: CompleteStructuredDataParserProps) => Article | null = ({
    item,
    imagesMeta,
    pagedItem,
    organizationSchema,
    override,
    basePath,
    websiteSchema
}) => {
    // console.log('item', item, item?.authors, item?.taxons?.authors)
    if (!item?.structuredData?.parsed) return null
    const {
        structuredData = parseArticle(item, parseBasicSchemaProperties(item)),
        body,
        description: itemDescription
    } = item
    const { parsed: parsedStructuredData, data: intermediateData } = structuredData as ProcessedArticleStructuredData
    const { author: authorData, bodyMaxWords, summary } = intermediateData

    const { title: name, description: content } = applyOverrideableSeoProperties(
        {
            title: pagedItem?.name || parsedStructuredData.name?.toString(),
            description: stripHtml(pagedItem?.body || body) || itemDescription
        },
        override
    )
    const author = completeAuthorStructuredData(organizationSchema, authorData, basePath)
    const image = pagedItem?.images?.[0]?.url || imagesMeta?.[0]?.url
    const articleBodyWords = content?.split(' ') || []
    const wasTooLong = articleBodyWords.length > bodyMaxWords
    const shortenedArticleBody = articleBodyWords.slice(0, bodyMaxWords).join(' ')
    const articleBody = `${shortenedArticleBody}${wasTooLong ? '...' : ''}`

    // if article type LIVE override @type to LiveBlogPosting
    // @NOTE: this is a temporary solution until we have a better way to determine article type
    // Uncoment after review
    // const isLive = item?.taxons?.['article-type']?.[0]?.title === 'Live'
    // if (isLive) {
    //     parsedStructuredData['@type'] = 'LiveBlogPosting'
    //     // parsedStructuredData.coverageStartTime = parsedStructuredData.datePublished
    // }

    // @NOTE: rest of the properties are appended once frontend values are known
    return {
        ...parsedStructuredData,
        name: undefined,
        headline: name,
        image,
        author,
        publisher: parseReferencableThing(organizationSchema),
        ...(!summary && {
            thumbnailUrl: image,
            articleBody,
            isPartOf: parseReferencableThing(websiteSchema)
        })
    }
}

const parseArticle = (
    item: EntityElastic,
    basicProperties: ParsedBasicProperties,
    summary = false,
    bodyMaxWords = 100
): ProcessedArticleStructuredData => {
    const { title, description, sections, publishDate, updateDate, author } = basicProperties
    const data = { summary, bodyMaxWords, author }
    const parsed: Article = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '@type': 'NewsArticle',
        name: title,
        headline: truncate(title, 110, '...'),
        ...(!summary && {
            description,
            articleSection: sections,
            inLanguage: 'Croatian',
            // thumbnailUrl: imageUrl,
            datePublished: publishDate,
            dateModified: updateDate
            // mainEntityOfPage: url
        })
    }

    return {
        data,
        parsed
    }
}

export default parseArticle
